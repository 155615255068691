<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(isSbPage(blok) ? {} : { id: `${blok.id}` }) }"
    class="relative w-full"
    :threshold="0.5"
    @enter="onIntersect"
  >
    <FimPicture
      v-if="mobile || desktop"
      class="picture"
      provider="storyblok"
      :preload="preloadMedia"
      :loading="loading"
      :strict="hideMobile"
      :mobile="hideMobile ? undefined : mobile"
      :desktop="desktop"
      :focus-mobile="focusMobile"
      :focus-desktop="focusDesktop"
      :alt="alt"
      :layout="layout"
      :widths="widths"
      :sizes="sizes"
      :max-width="maxWidth"
      :ratio-mobile="mobileRatio"
      :ratio-desktop="desktopRatio"
      :object-fit="objectFit"
      :object-position="objectPosition"
    />
  </Intersect>
</template>

<script setup lang="ts">
import { isSbPage, isSbImage, isSbAsset } from '~/utils/typeguards'
import { getFocusPoint } from '../composables/cmsImages'
import type { Sbasset, SbImage, SbPage } from '../types/storyblok'
import type { ObjectFit, ObjectPosition } from '~/constants/ui'

export type CmsImageProps = {
  height?: number
  ratioMobile?: number
  ratioDesktop?: number
  isTeaser?: boolean
  loading?: 'lazy' | 'eager'
  preloadMedia?: boolean
  objectFit?: ObjectFit
  objectPosition?: ObjectPosition
  widths?: Partial<Record<keyof typeof BREAKPOINTS, number>>
  maxWidth?: number // 1400
  sizes?: string
  blok: SbImage | Sbasset | SbPage
}

const props = withDefaults(defineProps<CmsImageProps>(), {
  loading: 'lazy',
  height: undefined,
  ratioMobile: undefined,
  ratioDesktop: undefined,
  objectFit: undefined,
  objectPosition: undefined,
  widths: undefined,
  maxWidth: 1400,
  sizes: undefined,
})

const mobile = computed(() => {
  if (isSbPage(props.blok)) {
    return props.blok?.teaser_image_mobile?.filename
  }

  if (isSbImage(props.blok)) {
    return props.blok?.mobile_image?.filename
  }

  return undefined
})

const desktop = computed(() => {
  if (isSbPage(props.blok)) {
    return props.blok?.teaser_image?.filename
  }

  if (isSbImage(props.blok)) {
    return props.blok?.desktop_image?.filename
  }

  if (isSbAsset(props.blok)) {
    return props.blok.filename
  }

  return undefined
})

const focusMobile = computed(() => {
  if (isSbPage(props.blok)) {
    return getFocusPoint(props.blok.teaser_image_mobile)
  }

  if (isSbImage(props.blok)) {
    return getFocusPoint(props.blok.mobile_image)
  }
  return undefined
})

const focusDesktop = computed(() => {
  if (isSbPage(props.blok)) {
    return getFocusPoint(props.blok.teaser_image)
  }

  if (isSbImage(props.blok)) {
    return getFocusPoint(props.blok.desktop_image)
  }

  if (isSbAsset(props.blok)) {
    return getFocusPoint(props.blok)
  }

  return undefined
})

const alt = computed(() => {
  if (isSbPage(props.blok)) {
    return (
      props.blok?.teaser_image?.alt ??
      props.blok?.teaser_image_mobile?.alt ??
      ''
    )
  }

  if (isSbImage(props.blok)) {
    return props.blok?.desktop_image?.alt ?? props.blok?.mobile_image?.alt ?? ''
  }

  if (isSbAsset(props.blok)) {
    return props.blok?.alt ?? ''
  }

  return undefined
})

const hideMobile = computed(() =>
  isSbImage(props.blok) ? props.blok?.hide_mobile ?? false : false,
)

const toNumber = (input: string | number) =>
  typeof input === 'string' ? parseFloat(input) : input

const layout = computed(() =>
  isSbImage(props.blok) ? toPictureLayout(props.blok?.layout, 'raw') : 'raw',
)
const mobileRatio = computed(() => {
  if (props.ratioMobile) {
    return props.ratioMobile
  }
  if (isSbImage(props.blok) && props.blok.mobile_ratio) {
    return toNumber(props.blok.mobile_ratio)
  }

  return undefined
})

const desktopRatio = computed(() => {
  if (props.ratioDesktop) {
    return props.ratioDesktop
  }
  if (isSbImage(props.blok) && props.blok.desktop_ratio) {
    return toNumber(props.blok.desktop_ratio)
  }

  return undefined
})

const { trackPromotion } = await useTrackingEvents()

const onIntersect = (_: IntersectionObserverEntry, stop: () => void) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotion('FielmannBasic_EC_ViewPromotion', props.blok)
  }
  stop()
}
</script>
